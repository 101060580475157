<template>
  <div class="mybsif">
    <div class="head">
      <span class="name">修改基本信息</span>
    </div>
    <ul class="ul">
      <li>
        <div class="top">
          <div class="lt">
            <span class="name">我的昵称</span>
            <span class="txt">{{
             userInfo.review_nick_name ||  userInfo.nick_name
            }}</span>
            <el-tag v-if="userInfo.review_nick_name" size="mini" type="warning">审核中</el-tag>
          </div>
          <div class="rt" @click="showForm('formVisible')">编辑</div>
        </div>
        <el-form
          v-show="formVisible"
          class="el__form"
          ref="form"
          :model="form"
          :rules="rules"
        >
          <el-form-item prop="name">
            <el-input
              placeholder="请输入新的昵称"
              v-model="form.name"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="el__btn" @click="submit('form')">提交</el-button>
          </el-form-item>
        </el-form>
      </li>
      <li>
        <div class="top">
          <div class="lt">
            <span class="name">头像</span>
            <el-image :src="userInfo.review_avatar || userInfo.avatar" style="width:100px"></el-image>
            <el-tag v-if="userInfo.review_avatar" size="mini" type="warning">审核中</el-tag>
          </div>
          <div class="rt" @click="showForm('form2Visible')">上传头像</div>
        </div>
        <el-form
          v-show="form2Visible"
          class="el__form"
          ref="form2"
          :model="form2"
          :rules="rules2"
        >
          <el-form-item prop="avatar">
            <upload-file
              accept="image/*"
              :limit="1"
              @change="changeAvatar"
              :value="form.avatar"
            ></upload-file>
          </el-form-item>
          <el-form-item>
            <el-button class="el__btn short" @click="submit2('form2')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </li>
      <li>
        <div class="top">
          <div class="lt">
            <span class="name">实名认证</span>
            <span class="txt">{{userInfo.id_card?'已认证':'未认证'}}</span>
          </div>
          <div v-if="!userInfo.id_card" class="rt" @click="showForm('form1Visible')">去认证</div>
        </div>
        <el-form
          v-show="form1Visible"
          class="el__form"
          ref="form1"
          :model="form1"
          :rules="rules1"
        >
          <el-form-item prop="realName">
            <el-input
              placeholder="请输入真实姓名"
              v-model="form1.realName"
            ></el-input>
          </el-form-item>
          <el-form-item prop="idCard">
            <el-input
              placeholder="请输入身份证号码"
              v-model="form1.idCard"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="el__btn" @click="submit1('form1')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </li>
    </ul>
  </div>
</template>

<script>
import { checkMobile, phoneDesensitization } from "@/utils/tools.js";
import { getSms,reviewInfo,refreshToken } from "@/api/outh";
import { putFile } from "@/api/oss";
import { mapState, mapActions } from "vuex";
import UploadFile from "./UploadFile";
export default {
  components: {
    UploadFile,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      form: {
        name: "",
      },
      rules: {
        name: [{ required: true, message: "请输入新的昵称", trigger: "blur" }],
      },
      form1: {
        realName: "",
        idCard: "",
      },
      rules1: {
        realName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        idCard: [
          { required: true, message: "请输入身份证号码", trigger: "blur" },
          {
            pattern:
              /(^\d{8}(0\d|10|11|12)([0-2]\d|30|31)\d{3}$)|(^\d{6}(18|19|20)\d{2}(0\d|10|11|12)([0-2]\d|30|31)\d{3}(\d|X|x)$)/,
            message: "请输入正确的身份证号码",
          },
        ],
      },
      form2: {
        avatar: [],
      },
      rules2: {
        avatar: [{ required: true, message: "请上传头像", trigger: "blur" }],
      },
      formVisible: false,
      form1Visible: false,
      form2Visible: false,
    };
  },
  filters: {
    nopass(val) {
      return phoneDesensitization(val, "*");
    },
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    changeAvatar(val) {
      this.form2.avatar = val;
    },
    async refreshToken(){
      const res = await refreshToken(this.userInfo.refresh_token);
      this.setUserInfo(res);
    },
    showForm(formName) {
      this[formName] = !this[formName];
    },
    getCode(phone) {
      checkMobile(null, phone, (msg) => {
        if (msg) {
          return this.$message.error(msg);
        } else {
          return this.getSms(phone);
        }
      });
    },
    async getSms(phone) {
      const res = await getSms(phone);
      console.log(res);
      this.$message.success("短信验证码发送成功");
      this.form.key = res.data;
      this.countTime();
    },
    countTime() {
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.sendDisabled = true;
        this.smsText = this.count + "s后获取";
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            this.smsText = this.count + "s后获取";
          } else {
            this.sendDisabled = false;
            clearInterval(this.timer);
            this.timer = null;
            this.smsText = "发送验证码";
          }
        }, 1000);
      }
    },
    submit(formName) {
      const { name } = this.form;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await reviewInfo({nickName:name,reviewType:2});
          // console.log(res);
          res && this.$message.success("提交成功,审核中...");
          this.refreshToken()
        } else {
          return false;
        }
      });
    },
    submit1(formName) {
      const { realName,idCard } = this.form1;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await reviewInfo({realName,idCard,reviewType:3});
          // console.log(res);
          res && this.$message.success("提交成功,审核中...");
          this.refreshToken()
        } else {
          return false;
        }
      });
    },
    submit2(formName) {
      const { avatar } = this.form2;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const imgRes = await putFile(avatar[0].raw);
          const link = imgRes.data.link
          const res = await reviewInfo({avatar:link,reviewType:1});
          res && this.$message.success("提交成功,审核中...");
          this.refreshToken()
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  padding-bottom: 20px;
  box-shadow: 0px -1px 0px 0px #e9e9e9 inset;
  .name {
    font-size: 20px;
    font-weight: 700;
    color: #333333;
  }
}
.ul {
  padding: 0 10px;
  li {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 34px 0;
      box-shadow: 0px -1px 0px 0px #f1f3f6 inset;
      .lt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 14px;
          color: #000000;
          padding-left: 10px;
          width: 100px;
        }
        .txt {
          font-size: 14px;
          color: #666666;
        }
      }
      .rt {
        font-size: 14px;
        color: #335db0;
        cursor: pointer;
      }
    }
    .el__form {
      margin-top: 24px;
      .el-input {
        width: 350px;
      }
      .el__btn {
        width: 350px;
        background: #5cbd31;
        border: 0;
        color: #ffffff;
      }
      .short {
        width: 148px;
      }
    }
  }
}
</style>