<template>
  <div class="personalCenter">
    <div class="location">
      <i class="el-icon-place"></i>
      <el-breadcrumb
        class="el__breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item>您当前的位置</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>个人中心</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="left">
        <div class="user">
          <el-image :src="userInfo.avatar?userInfo.avatar:require('@/assets/images/common/avatar.png')"></el-image>
          <span>{{ userInfo.nick_name }}</span>
          <span>{{ userInfo.account | nopass }}</span>
        </div>
        <ul class="tabs">
          <li
            :class="{ current: item.value == current }"
            v-for="(item, index) in tabs"
            :key="index"
            @click="onItemClick(item)"
          >
            {{ item.key }}
          </li>
        </ul>
      </div>
      <div class="right">
        <component :is="tabs[current - 1].component"></component>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { phoneDesensitization } from "@/utils/tools.js";
import MyQuestion from "@/components/MyQuestion";
import MyAnswer from "@/components/MyAnswer";
import MyLike from "@/components/MyLike";
import MyAccount from "@/components/MyAccount";
import MyBaseInfo from "@/components/MyBaseInfo";
export default {
  name: "personalCenter",
  components: {
    MyQuestion,
    MyAnswer,
    MyLike,
    MyAccount,
    MyBaseInfo,
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
    editPwd() {
      return this.$route.query.editPwd;
    },
  },
  data() {
    return {
      current: 1,
      tabs: [
        {
          key: "我的提问",
          value: 1,
          component: "MyQuestion",
        },
        {
          key: "我的回答",
          value: 2,
          component: "MyAnswer",
        },
        {
          key: "我的点赞",
          value: 3,
          component: "MyLike",
        },
        {
          key: "账号安全",
          value: 4,
          component: "MyAccount",
        },
        {
          key: "修改基本信息",
          value: 5,
          component: "MyBaseInfo",
        },
      ],
    };
  },
  mounted() {
    this.current = this.editPwd ? 4 : 1;
  },
  filters: {
    nopass(val) {
      return phoneDesensitization(val, "*");
    },
  },
  methods: {
    onItemClick(item) {
      this.current = item.value;
    },
  },
};
</script>

<style lang="scss" scoped>
.personalCenter {
  background: #ffffff;
  .location {
    padding: 20px;
    display: flex;
    align-items: center;
    .el__breadcrumb {
      margin-left: 8px;
    }
  }
  .content {
    display: flex;
    min-height: 720px;
    .left {
      width: 168px;
      background: #f9fafc;
      .user {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        box-shadow: 0px -1px 0px 0px #e7e9f5 inset;
        padding: 20px;
        .el-image {
          width: 40px;
          height: 40px;
        }
        span {
          font-size: 14px;
          color: #333333;
          line-height: 24px;
        }
      }
      .tabs {
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        li {
          height: 46px;
          font-size: 14px;
          color: #333333;
          padding-left: 26px;
          line-height: 46px;
          &.current {
            background: #ffffff;
            box-shadow: 4px 0px 0px 0px #5cbd31 inset;
          }
        }
      }
    }
    .right {
      flex: 1;
      padding: 20px;
      box-shadow: -1px 1px 0px 0px #e9e9e9 inset, 0px -1px 0px 0px #e9e9e9 inset;
    }
  }
}
</style>