<template>
  <div class="mylike">
    <div class="head">
      <span class="name">账号安全</span>
    </div>
    <ul class="ul">
      <li>
        <div class="top">
          <div class="lt">
            <el-image
              class="icon"
              :src="require('images/personalCenter/lock.png')"
            ></el-image>
            <span class="name">绑定手机</span>
            <span class="txt">{{ userInfo.account | nopass }}</span>
          </div>
          <div class="rt" @click="showForm('formVisible')">更换手机号码</div>
        </div>
        <el-form
          v-show="formVisible"
          class="el__form"
          ref="form"
          :model="form"
          :rules="rules"
        >
          <el-form-item prop="phone">
            <el-input placeholder="请输入手机号码" v-model="form.phone">
              <template slot="prepend">+86</template></el-input
            >
          </el-form-item>
          <el-form-item prop="captchaCode">
            <el-input
              prefix-icon="el-icon-key"
              placeholder="请输入图片验证码"
              v-model="form.captchaCode"
              class="captcha__code"
            >
              <el-image
                slot="append"
                @click="getCaptchaCode"
                :src="form.captchaImg"
              ></el-image>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input placeholder="请输入验证码" v-model="form.code">
              <el-button
                slot="append"
                :disabled="sendDisabled"
                @click="getCode(form.phone, form.captchaCode)"
                >{{ smsText }}</el-button
              >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="el__btn" @click="submit('form')">提交</el-button>
          </el-form-item>
        </el-form>
      </li>
      <li>
        <div class="top">
          <div class="lt">
            <el-image
              class="icon"
              :src="require('images/personalCenter/phone.png')"
            ></el-image>
            <span class="name">密码</span>
            <span class="txt">********</span>
          </div>
          <div class="rt" @click="showForm('form1Visible')">修改密码</div>
        </div>
        <el-form
          v-show="form1Visible"
          class="el__form"
          ref="form1"
          :model="form1"
          :rules="rules1"
        >
          <el-form-item prop="oldPassword">
            <!-- v-if="!editPwd" -->
            <el-input
              placeholder="请输入旧的密码"
              v-model="form1.oldPassword"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="newPassword">
            <el-input
              placeholder="请输入新的密码"
              v-model="form1.newPassword"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item prop="newPassword1">
            <el-input
              placeholder="请确认新的密码"
              v-model="form1.newPassword1"
              show-password
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="el__btn" @click="submit1('form1')"
              >提交</el-button
            >
          </el-form-item>
        </el-form>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  checkMobile,
  phoneDesensitization,
  validatePassword,
} from "@/utils/tools.js";
import md5 from "js-md5";
import {
  getCaptcha,
  getSms,
  updatePhone,
  updatePassword,
  refreshToken,
} from "@/api/outh";
import { validatePassStrength } from "@/utils/tools.js";
import { mapState, mapActions } from "vuex";
export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
    editPwd() {
      return this.$route.query.editPwd;
    },
  },
  data() {
    return {
      smsText: "发送验证码",
      sendDisabled: false,
      count: 60,
      timer: null,
      form: {
        phone: "",
        code: "",
        key: "",
        captchaCode: "",
        captchaKey: "",
        captchaImg: "",
      },
      rules: {
        phone: [{ validator: checkMobile, trigger: "blur" }],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        captchaCode: [
          { required: true, message: "请输入图片验证码", trigger: "blur" },
        ],
      },
      form1: {
        oldPassword: "",
        newPassword: "",
        newPassword1: "",
      },
      rules1: {
        oldPassword: [
          { required: true, message: "请输入当前的密码", trigger: "blur" },
        ],
        newPassword: [{ validator: validatePassStrength, trigger: "blur" }],
        newPassword1: [
          {
            validator: (rule, value, callback) =>
              validatePassword(rule, value, callback, this.form1.newPassword),
            trigger: "blur",
          },
        ],
      },
      formVisible: false,
      form1Visible: false,
    };
  },
  filters: {
    nopass(val) {
      return phoneDesensitization(val, "*");
    },
  },
  mounted() {
    this.getCaptchaCode();
    if (this.editPwd) {
      this.form1Visible = true;
      this.form1.oldPassword = this.userInfo.default_password_md5;
    }
  },
  methods: {
    ...mapActions(["setUserInfo"]),
    async getCaptchaCode() {
      const res = await getCaptcha();
      // console.log(res);
      this.form.captchaImg = res.image;
      this.form.captchaKey = res.key;
    },
    async refreshToken() {
      const res = await refreshToken(this.userInfo.refresh_token);
      this.setUserInfo(res);
    },
    showForm(formName) {
      this[formName] = !this[formName];
    },
    getCode(phone, captchaCode) {
      checkMobile(null, phone, (msg) => {
        if (msg) {
          return this.$message.error(msg);
        } else {
          if (!captchaCode) {
            return this.$message.error("图片验证码不能为空");
          } else {
            return this.getSms(phone, this.form.captchaKey, captchaCode);
          }
        }
      });
    },
    async getSms(phone, smsKey, smsCode) {
      // const res = await getSms(phone);
      // this.$message.success("短信验证码发送成功");
      // this.form.key = res.data;
      // this.countTime();

      getSms(phone, smsKey, smsCode)
        .then((res) => {
          this.$message.success("短信验证码发送成功");
          this.form.key = res.data;
          this.countTime();
        })
        .catch((err) => {
          console.log("验证码错误====", err);
          this.getCaptchaCode();
        });
    },
    countTime() {
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.sendDisabled = true;
        this.smsText = this.count + "s后获取";
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
            this.smsText = this.count + "s后获取";
          } else {
            this.sendDisabled = false;
            clearInterval(this.timer);
            this.timer = null;
            this.smsText = "发送验证码";
          }
        }, 1000);
      }
    },
    submit(formName) {
      const { key, code, phone } = this.form;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await updatePhone(key, code, phone);
          // console.log(res);
          res && this.$message.success("修改成功");
          this.refreshToken();
        } else {
          return false;
        }
      });
    },
    submit1(formName) {
      let { oldPassword, newPassword, newPassword1 } = this.form1;
      // oldPassword = this.editPwd
      //   ? this.userInfo.default_password_md5
      //   : oldPassword;
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const res = await updatePassword(
            oldPassword,
            newPassword,
            newPassword1
          );
          // console.log(res);
          res && this.$message.success("修改成功");
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  padding-bottom: 20px;
  box-shadow: 0px -1px 0px 0px #e9e9e9 inset;
  .name {
    font-size: 20px;
    font-weight: 700;
    color: #333333;
  }
}
.ul {
  padding: 0 10px;
  li {
    .top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 34px 0;
      box-shadow: 0px -1px 0px 0px #f1f3f6 inset;
      .lt {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icon {
          width: 34px;
          height: 34px;
        }
        .name {
          font-size: 14px;
          color: #000000;
          padding-left: 10px;
          width: 100px;
        }
        .txt {
          font-size: 14px;
          color: #666666;
        }
        .icon1 {
          width: 16px;
          height: 16px;
          padding-left: 10px;
        }
      }
      .rt {
        font-size: 14px;
        color: #335db0;
        cursor: pointer;
      }
    }
    .el__form {
      margin-top: 24px;
      .el-input {
        width: 350px;
      }
      /deep/ .captcha__code {
        .el-input-group__append {
          background-color: #ffffff;
        }
        .el-image {
          width: 90px;
        }
      }
      .el__btn {
        width: 350px;
        background: #5cbd31;
        border: 0;
        color: #ffffff;
      }
    }
  }
}
</style>
