<template>
  <div>
    <el-upload
      :class="{'exceed': limit <= fileList.length}"
      ref="upload"
      action="#"
      :multiple="true"
      :list-type="listType"
      :auto-upload="false"
      :limit="limit"
      :accept="accept"
      :show-file-list="true"
      :file-list="fileList"
      :before-upload="beforeUpload"
      :on-preview="handlePreview"
      :on-remove="handleRemove"
      :before-remove="beforeRemove"
      :on-exceed="handleExceed"
      :on-change="handleChange"
      :on-success="handleSuccess"
    >
      <i slot="default" class="el-icon-plus"></i>
      <p slot="tip" class="el-upload__tip">{{uploadTip}}</p>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible">
      <img v-if="/image/.test(accept)" width="100%" :src="dialogFileUrl" />
      <video
        v-if="/video/.test(accept)"
        width="100%"
        :src="dialogFileUrl"
        controls
      />
    </el-dialog>
  </div>
</template>

<script>
export default {
  computed:{
    uploadTip(){
      return /image/.test(this.accept)?'只能上传jpg/png格式的文件，且不超过500kb':'只能上传mp4格式的文件，且不超过20MB'
    }
  },
  props: {
    listType: {
      type: String,
      default: "picture-card",
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    accept: {
      type: String,
      default: "*",
    },
    limit: {
      type: Number,
      default: 9,
    },
  },
  data() {
    return {
      dialogVisible: false,
      dialogFileUrl: "",
      fileList: [],
    };
  },
  watch: {
    value(val) {
      this.fileList = val;
    },
    fileList(val) {
      this.$emit("change", val);
    },
  },
  methods: {
    handleSuccess(response, file, fileList) {
      console.log("handleSuccess", response, file, fileList);
    },
    handleChange(file, fileList) {
      console.log("handleChange", file, fileList);
      if( /image/.test(this.accept)){ // 图片类型
        if(!/image/.test(file.raw.type)){
          fileList.splice(fileList.length-1,1)
          return this.$message.error('请上传正确格式的图片!');      
        }
        if(file.raw.size/1024>500){
          fileList.splice(fileList.length-1,1)
          return this.$message.error('图片大小不能超过500kb');      
        }
      }
      if( /video/.test(this.accept)){ // 视频类型
        if(!/video/.test(file.raw.type)){
          fileList.splice(fileList.length-1,1)
          return this.$message.error('请上传正确格式的视频!');      
        }
        if(file.raw.size/1024/1024>20){
          fileList.splice(fileList.length-1,1)
          return this.$message.error('图片大小不能超过20mb');      
        }
      }
      this.fileList = fileList;
    },
    beforeUpload(file) {
      console.log("beforeUpload", file);
      return true;
    },
    handleRemove(file, fileList) {
      console.log("handleRemove", file, fileList);
      this.fileList = fileList;
    },
    handlePreview(file) {
      console.log("handlePreview", file);
      this.dialogFileUrl = file.url;
      this.dialogVisible = true;
    },
    handleExceed(files, fileList) {
      console.log("handleExceed", files, fileList);
      this.$message.warning(`最多可上传 ${this.limit} 个文件`);
    },
    beforeRemove(file, fileList) {
      console.log("beforeRemove", file, fileList);
      return true;
    },
  },
};
</script>

<style>
.exceed .el-upload--picture-card {
  display: none;
}
.upload__tip {
  font-size: 12px;
  color: #b3b3b3;
}
</style>