import request from '../utils/request';

export const putFile = (file) => {
  const formData = new FormData();
  formData.append("file", file);
  return request({
    url: "/api/blade-resource/oss/endpoint/put-file",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

export const mutiUpload = (files) => {
	return Promise.all(files.map((file) => {
		return putFile(file)
	}));
}