<template>
  <div class="myqst">
    <div class="head">
      <span class="name">我的提问</span>
      <ul class="tabs">
        <li
          :class="{ current: item.value == current }"
          v-for="(item, index) in tabs"
          :key="index"
          @click="onItemClick(item)"
        >
          {{ item.key }}
        </li>
      </ul>
    </div>
    <table class="table" v-if="askList.length">
      <tr>
        <th>标题</th>
        <th>问政部门</th>
        <th>发布日期</th>
        <th>状态</th>
        <th>操作</th>
      </tr>
      <tr v-for="item in askList" :key="item.id">
        <td>{{ item.title }}</td>
        <td>{{ item.deptName }}</td>
        <td>{{ item.handelTime }}</td>
        <td v-html="item.statusName"></td>
        <td class="action">
          <span @click="onDetail(item)">查看</span>
          <span v-if="item.status === 0" @click="onEdit(item)">修改</span>
          <span v-if="item.status <= 1" @click="onDel(item)">删除</span>
          <span v-if="item.status === 20" @click="onEvaluation(item)"
            >评价</span
          >
        </td>
      </tr>
    </table>
    <el-empty v-else description="暂无数据"></el-empty>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="currentSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <el-dialog
      append-to-body
      :visible.sync="evaluationDialog"
      title="评价"
      width="520px"
    >
      <div class="detail">
        <div class="cell">
          <div class="cell-label">问政标题</div>
          <div class="cell-value">
            {{ selectDetail.title }}
          </div>
        </div>
        <div class="cell">
          <div class="cell-label">理政部门</div>
          <div class="cell-value">{{ selectDetail.deptName }}</div>
        </div>
        <div class="cell">
          <div class="cell-label">回复时间</div>
          <div class="cell-value">{{ selectDetail.updateTime }}</div>
        </div>
        <img
          class="detail-status"
          :src="require('@/assets/images/online/reply.png')"
        />
      </div>
      <el-form
        ref="evaluationForm"
        class="evaluation-form"
        label-position="left"
        :model="evaluationForm"
        label-width="80px"
      >
        <div class="cell">
          <div class="cell-label">我的评价</div>
          <div class="cell-value">
            <el-rate
              v-model="evaluationForm.satisfyStatus"
              :max="5"
              :show-text="true"
              :texts="['不满意', '不满意', '满意', '非常满意', '非常满意']"
            ></el-rate>
          </div>
        </div>
        <div class="cell">
          <div class="cell-label">评价内容</div>
          <div class="cell-value">
            <el-input
              type="textarea"
              :rows="10"
              placeholder="请输入内容"
              v-model="evaluationForm.content"
            >
            </el-input>
          </div>
        </div>
        <el-form-item>
          <el-button
            type="primary"
            :loading="evaluationFormLoad"
            @click="submitForm()"
            >提交</el-button
          >
          <el-button :loading="evaluationFormLoad" @click="resetForm()"
            >重置</el-button
          >
        </el-form-item>
        <!-- <el-form-item label="我的评价"> -->
        <!-- :colors="['#FF9800']" -->
        <!-- <el-rate v-model="evaluationForm.satisfyStatus" :max="3" ></el-rate> -->
        <!-- </el-form-item> -->
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { getAskList, delAsk, addFeedbackAsk } from "@/api/wzasksevent";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      current: "",
      tabs: [
        {
          key: "全部",
          value: "",
        },
        {
          key: "未受理",
          value: 1,
        },
        {
          key: "已受理",
          value: 10,
        },
        {
          key: "已答复",
          value: 20,
        },
      ],
      currentPage: 1,
      currentSize: 10,
      total: 0,
      askList: [],

      selectDetail: {},
      evaluationDialog: false,
      evaluationFormLoad: false,
      evaluationForm: {
        satisfyStatus: 5,
        content: "",
      },
    };
  },
  mounted() {
    this.getAskList();
  },
  methods: {
    onEdit(item) {
      this.$router.push({
        path: "/web/ask/",
        query: {
          id: item.id,
        },
      });
    },
    async onDel(item) {
      const res = await delAsk(item.id);
      res && this.$message.success("删除成功");
      this.getAskList();
    },
    onDetail(item) {
      this.$router.push({
        path:"/web/detail/",
        query:{
          id:item.id,
        }
      });
    },
    onEvaluation(item) {
      // 点击评价
      this.selectDetail = item;
      this.evaluationDialog = true;
    },
    async getAskList() {
      const res = await getAskList(this.currentPage, this.currentSize, {
        status: this.current,
        userId: this.userInfo.user_id,
      });
      // console.log(res);
      this.askList = res.data.records;
      this.total = res.data.total;
    },
    onItemClick(item) {
      this.current = item.value;
      this.getAskList();
    },
    handleSizeChange(val) {
      this.currentSize = val;
      this.getAskList();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getAskList();
    },

    submitForm() {
      this.$refs.evaluationForm.validate((valid) => {
        if (valid) {
          this.evaluationFormLoad = true;

          let satisfyStatus = this.evaluationForm.satisfyStatus;

          const form = {
            satisfyStatus:
              satisfyStatus > 3 ? "0" : satisfyStatus == 3 ? "1" : "2",
            content: this.evaluationForm.content,
            targetId: this.selectDetail.id,
          };
          addFeedbackAsk(form).then((res) => {
            this.evaluationFormLoad = false;
            this.evaluationDialog = false;
            if (res.success) {
              this.$message.success("评价成功");
              this.getAskList();
            }
          });
        }
      });
    },
    resetForm() {
      this.evaluationForm = {
        satisfyStatus: 5,
        content: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  justify-content: space-between;
  .name {
    font-size: 20px;
    font-weight: 700;
    color: #333333;
  }
  .tabs {
    display: flex;
    align-items: center;
    li {
      font-size: 14px;
      color: #999999;
      margin-left: 20px;
      cursor: pointer;
      &.current {
        font-weight: 700;
        font-size: 16px;
        color: #333333;
      }
    }
  }
}
.table {
  width: 100%;
  margin-top: 25px;
  th {
    background: #f9fafc;
    font-size: 14px;
    color: #333333;
    text-align: center;
    padding: 15px 0;
  }
  td {
    font-size: 14px;
    color: #333333;
    text-align: center;
    padding: 15px 0;
    box-shadow: 0px -1px 0px 0px #f1f3f6 inset;
    min-width: 100px;
    &.action {
      font-size: 14px;
      color: #335db0;
      cursor: pointer;
      span {
        padding: 0 10px;
        &:not(:last-child) {
          border-right: 1px solid #335db0;
        }
      }
    }
  }
}
.el-pagination {
  padding: 20px;
}
.detail {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  padding: 15px 0;
  position: relative;
  .detail-status {
    position: absolute;
    width: 100px;
    right: 0;
    bottom: 0;
  }
  .cell {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    .cell-label {
      margin-right: 14px;
      flex-shrink: 0;
      color: #666666;
    }
    .cell-value {
      color: #2c2f35;
    }
  }
}
.evaluation-form {
  padding: 12px 0;
  ::v-deep {
    .el-rate__icon {
      font-size: 30px;
    }
  }
  .cell {
    font-family: "Source Han Sans CN";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    display: flex;
    margin-bottom: 18px;
    .cell-label {
      margin-right: 14px;
      flex-shrink: 0;
      color: #666666;
    }
    .cell-value {
      color: #2c2f35;
      flex: 1;
    }
  }
}
</style>
