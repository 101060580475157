<template>
  <div class="mylike">
    <div class="head">
      <span class="name">我的点赞</span>
      <ul class="tabs" v-if="false">
        <li
          :class="{ current: item.value == current }"
          v-for="(item, index) in tabs"
          :key="index"
          @click="onItemClick(item)"
        >
          {{ item.key }}
        </li>
      </ul>
    </div>
    <table class="table" v-if="likeList.length">
      <tr>
        <th>标题</th>
        <th>{{current==1?'部门':'标签'}}</th>
        <th>发布日期</th>
        <th>操作</th>
      </tr>
      <tr v-for="item in likeList" :key="item.id">
        <td>{{ item.targetTitle || item.title }}</td>
        <td>{{ item.type == 1 ? item.deptName:item.zkEventTitle }}</td>
        <td>{{ item.createTime }}</td>
        <td class="action">
          <span @click="onDetail(item)">查看</span>
        </td>
      </tr>
    </table>
    <el-empty v-else description="暂无数据"></el-empty>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="currentSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getMyLikes } from "@/api/wzaskslike";
export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
    }),
  },
  data() {
    return {
      current: 1,
      tabs: [
        {
          key: "问政",
          value: 1,
        },
        {
          key: "智库",
          value: 3,
        },
      ],
      currentPage: 1,
      currentSize: 10,
      total: 0,
      likeList: [],
    };
  },
  watch: {
    current: {
      handler(val) {
        this.getMyLikes(val);
      },
      immediate: true,
    },
  },
  activated(){
    console.log('activated')
  }, 
  deactivated(){
    console.log('deactivated')
  },

  methods: {
    onDetail(item) {
      if (item.type === 1) {
        this.$router.push({
        path:"/web/detail/",
        query:{
          id:item.finalId,
        }
      });
      } else {
        this.$router.push("/web/zkdetail/" + item.finalId);
      }
    },
    async getMyLikes(type) {
      const res = await getMyLikes(this.currentPage, this.currentSize,type);
      // console.log(res);
      this.likeList = res.data.records;
      this.total = res.data.total;
    },
    onItemClick(item) {
      this.current = item.value;
    },
    handleSizeChange(val) {
      this.currentSize = val;
      this.getMyLikes(this.current);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getMyLikes(this.current);
    },
  },
};
</script>

<style lang="scss" scoped>
.head {
  display: flex;
  justify-content: space-between;
  .name {
    font-size: 20px;
    font-weight: 700;
    color: #333333;
  }
  .tabs {
    display: flex;
    align-items: center;
    li {
      font-size: 14px;
      color: #999999;
      margin-left: 20px;
      cursor: pointer;
      &.current {
        font-size: 16px;
        font-weight: 700;
        color: #333333;
      }
    }
  }
}
.table {
  width: 100%;
  margin-top: 25px;
  th {
    background: #f9fafc;
    font-size: 14px;
    color: #333333;
    text-align: center;
    padding: 15px 0;
  }
  td {
    font-size: 14px;
    color: #333333;
    text-align: center;
    padding: 15px 0;
    box-shadow: 0px -1px 0px 0px #f1f3f6 inset;
    &.action {
      font-size: 14px;
      color: #335db0;
      cursor: pointer;
      span {
        padding: 0 10px;
        &:not(:last-child) {
          border-right: 1px solid #335db0;
        }
      }
    }
  }
}
.el-pagination {
  padding: 20px;
}
</style>